/*!
 * Bootstrap v4.0.0-beta.3 (https://getbootstrap.com)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 200;
  src: url("../Fonts/source-sans-pro-v11-latin_latin-ext_cyrillic-200.eot");
  src:
    local("Source Sans Pro ExtraLight"),
    local("SourceSansPro-ExtraLight"),
    url("../Fonts/source-sans-pro-v11-latin_latin-ext_cyrillic-200.eot?#iefix") format("embedded-opentype"),
    url("../Fonts/source-sans-pro-v11-latin_latin-ext_cyrillic-200.woff2") format("woff2"),
    url("../Fonts/source-sans-pro-v11-latin_latin-ext_cyrillic-200.woff") format("woff"),
    url("../Fonts/source-sans-pro-v11-latin_latin-ext_cyrillic-200.ttf") format("truetype"),
    url("../Fonts/source-sans-pro-v11-latin_latin-ext_cyrillic-200.svg#SourceSansPro") format("svg");
}

@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  src: url("../Fonts/source-sans-pro-v11-latin_latin-ext_cyrillic-regular.eot");
  src:
    local("Source Sans Pro Regular"),
    local("SourceSansPro-Regular"),
    url("../Fonts/source-sans-pro-v11-latin_latin-ext_cyrillic-regular.eot?#iefix") format("embedded-opentype"),
    url("../Fonts/source-sans-pro-v11-latin_latin-ext_cyrillic-regular.woff2") format("woff2"),
    url("../Fonts/source-sans-pro-v11-latin_latin-ext_cyrillic-regular.woff") format("woff"),
    url("../Fonts/source-sans-pro-v11-latin_latin-ext_cyrillic-regular.ttf") format("truetype"),
    url("../Fonts/source-sans-pro-v11-latin_latin-ext_cyrillic-regular.svg#SourceSansPro") format("svg");
}

@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 400;
  src: url("../Fonts/source-sans-pro-v11-latin_latin-ext_cyrillic-italic.eot");
  src:
    local("Source Sans Pro Italic"),
    local("SourceSansPro-Italic"),
    url("../Fonts/source-sans-pro-v11-latin_latin-ext_cyrillic-italic.eot?#iefix") format("embedded-opentype"),
    url("../Fonts/source-sans-pro-v11-latin_latin-ext_cyrillic-italic.woff2") format("woff2"),
    url("../Fonts/source-sans-pro-v11-latin_latin-ext_cyrillic-italic.woff") format("woff"),
    url("../Fonts/source-sans-pro-v11-latin_latin-ext_cyrillic-italic.ttf") format("truetype"),
    url("../Fonts/source-sans-pro-v11-latin_latin-ext_cyrillic-italic.svg#SourceSansPro") format("svg");
}

@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 900;
  src: url("../Fonts/source-sans-pro-v11-latin_latin-ext_cyrillic-900.eot");
  src:
    local("Source Sans Pro Black"),
    local("SourceSansPro-Black"),
    url("../Fonts/source-sans-pro-v11-latin_latin-ext_cyrillic-900.eot?#iefix") format("embedded-opentype"),
    url("../Fonts/source-sans-pro-v11-latin_latin-ext_cyrillic-900.woff2") format("woff2"),
    url("../Fonts/source-sans-pro-v11-latin_latin-ext_cyrillic-900.woff") format("woff"),
    url("../Fonts/source-sans-pro-v11-latin_latin-ext_cyrillic-900.ttf") format("truetype"),
    url("../Fonts/source-sans-pro-v11-latin_latin-ext_cyrillic-900.svg#SourceSansPro") format("svg");
}

@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 900;
  src: url("../Fonts/source-sans-pro-v11-latin_latin-ext_cyrillic-900italic.eot");
  src:
    local("Source Sans Pro Black Italic"),
    local("SourceSansPro-BlackItalic"),
    url("../Fonts/source-sans-pro-v11-latin_latin-ext_cyrillic-900italic.eot?#iefix") format("embedded-opentype"),
    url("../Fonts/source-sans-pro-v11-latin_latin-ext_cyrillic-900italic.woff2") format("woff2"),
    url("../Fonts/source-sans-pro-v11-latin_latin-ext_cyrillic-900italic.woff") format("woff"),
    url("../Fonts/source-sans-pro-v11-latin_latin-ext_cyrillic-900italic.ttf") format("truetype"),
    url("../Fonts/source-sans-pro-v11-latin_latin-ext_cyrillic-900italic.svg#SourceSansPro") format("svg");
}

@import "functions";
@import "variables";
@import "mixins";
@import "root";
@import "reboot";
@import "type";
@import "images";
@import "code";
@import "grid";
@import "tables";
@import "forms";
@import "buttons";
@import "transitions";
@import "dropdown";
@import "button-group";
@import "input-group";
@import "custom-forms";
@import "nav";
@import "navbar";
@import "card";
@import "breadcrumb";
@import "pagination";
@import "badge";
@import "jumbotron";
@import "alert";
@import "progress";
@import "media";
@import "list-group";
@import "close";
@import "modal";
@import "tooltip";
@import "popover";
@import "carousel";
@import "utilities";
@import "print";
